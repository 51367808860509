html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }
}

